<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('term-type:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t('add term type') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2> {{ $t('add term type') }}
                  </h2>
                </v-card-title>
                <v-card-text>
                  <v-text-field :label="$t('name')" v-model="newTermType.name" outlined dense color="#757575"
                    type="text"></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn :disabled="disableSubmitBtn" color="green" class="white--text" @click="submitNewGradeType"
                    :loading="submitBtnLoading">{{ $t('storage') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-text-field v-model="nameSearch" :label="$t('search by name')" dense outlined hide-details></v-text-field>
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" serverRoute="/term-type" :enableDelete="displayDeleteIcon">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-term-type-modal v-if="returnAbility('term-type:update')" @recordUpdated="fetchData"
            :recordData="row"></app-edit-term-type-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import editTermTypeModal from "./editTermTypeModal.vue";
export default {
  components: {
    appEditTermTypeModal: editTermTypeModal,
  },
  data() {
    return {
      nameSearch: "",
      dialog: false,
      submitBtnLoading: false,
      loading: false,
      newTermType: {
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "termType/getTableOptions",
    }),
    disableSubmitBtn() {
      if (!this.newTermType.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTermTypes: "termType/fetchTermTypes",
    }),
    async submitNewGradeType() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/term-type", this.newTermType);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
    fetchData(paginationValue) {
      this.fetchTermTypes({
        paginationValue,
        nameSearch: this.nameSearch,
      });
    },
  },
  created() {
    if (this.returnAbility("term-type:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>